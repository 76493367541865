import React, { useEffect } from "react";
import Header from "./Header";
import Content from "./Content";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "react-toastify/dist/ReactToastify.css";
import { useLoading } from "../context/loadingContext";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
export default function Layout(props) {
  const { loadingState, setLoadingState } = useLoading();

  return (
    <div className="wrapper">
      {loadingState.length && (
        <div
          style={{
            zIndex: "1000",
            display: "flex",
            flexDirection: "column-reverse",
            position: "fixed",
            top: "80vh",
            right: "20px",
          }}
        >
          {loadingState.map((refnumber) => (
            <div
              style={{
                display: "flex",
                background: " #FFCC00",
                padding: "20px",
                borderRadius: "6px",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <div>
                <p style={{ display: "flex", marginBottom: 0 }}>
                  <Spinner className="mr-2 " /> Video uploading of Property
                  reference no: {refnumber}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      <Header name={"testt"} />
      <Content />
      <NavBar />
      <Footer />
      <div id="sidebar-overlay">
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav ml-auto  ">
            <li style={{ marginRight: "10px" }} className="nav-item dropdown">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="pushmenu"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-bars" />
                </a>
              </li>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
