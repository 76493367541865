import React from "react";
import { NavLink } from "react-router-dom";
import { AiFillCar, AiOutlineDashboard, AiOutlineHome } from "react-icons/ai";
import { BiUser, BiCurrentLocation } from "react-icons/bi";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { GrUnorderedList } from "react-icons/gr";
import { MdOutlineContentPaste } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { GoPrimitiveDot } from "react-icons/go";
export default function NavBar() {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink
          to="/"
          className="brand-link removeline"
          style={{ textDecoration: "none" }}
        >
          <img
            src="/dist/img/logo.png"
            //className="brand-image elevation-3"
            style={{ opacity: ".8", height: 50, width: 200 }}
          />

          <span className="font-weight-light removeline hide-when-collapse">
            {/* Space Hub */}
          </span>
        </NavLink>
        <div class="dropdown-divider"></div>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link">
                  <i className="nav-icon far ">
                    <AiOutlineDashboard />
                  </i>
                  <p>Dashboard</p>
                </NavLink>
                <div class="dropdown-divider"></div>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <BiUser />
                  </i>
                  <p>
                    User Mangement
                    <i className="right fas fa-angle-right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/user" className="nav-link">
                      <GoPrimitiveDot className="mx-2" />
                      <p>Users Listing</p>
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="/adduser" className="nav-link">
                      <GoPrimitiveDot className="mx-2" />
                      <p>Invite Special User</p>
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink to="/specialUser" className="nav-link">
                      <GoPrimitiveDot className="mx-2" />
                      <p>Special User</p>
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <MdOutlineRealEstateAgent />
                  </i>
                  <p>
                    Agent Management
                    <i className="right fas fa-angle-right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/addAgent" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Add New</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/agentListings" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Listing</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <AiOutlineHome />
                  </i>
                  <p>
                    Property Management
                    <i className="right fas fa-angle-right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/addProperty" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Add New</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/propertyListing" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Listing</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <MdOutlineContentPaste />
                  </i>
                  <p>
                    FeedBack
                    <i className="right fas fa-angle-right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/feedBack" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Listing</p>
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="/addDriver" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Invite Driver</p>
                    </NavLink>
                  </li> */}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}
