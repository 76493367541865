import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  editProperty,
  singleProperty,
  getNextProperty,
} from "../../networkRequests/property";
import { toastError, toastSuccess, loadings } from "../../utlis/common";
import { useUploadForm } from "./../../utlis/s3Upload";

import { useHistory } from "react-router-dom";
import { awsBaseUrl } from "../../utlis/appConstants";

export default function SingleProperty(props) {
  const history = useHistory();
  const [propertyId, setPropertyId] = useState();
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: 35,
    lng: 35,
  });
  const [sqft, setSqft] = useState("");
  const [price, setPrice] = useState(0);
  const [beds, setBeds] = useState(0);
  const [bath, setBath] = useState(0);
  const [tenure, setTenure] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const [agentId, setAgentId] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const { isSuccess, uploadFile, progress } = useUploadForm();
  const _singleProperty = async () => {
    try {
      setLoader(true);
      const res = await singleProperty(props.location.state);
      console.log("ress", res);
      setPropertyId(res?.data?._id);
      setPrice(res?.data?.price);
      setSqft(res?.data?.sqft);
      setBeds(res?.data?.beds);
      setBath(res?.data?.bath);
      setTenure(res?.data?.tenure);
      setVideoUrl(res?.data?.videoUrl);
      setVideoFile(
        res?.data?.videoUrl !== "" ? awsBaseUrl + res?.data?.videoUrl : ""
      );
      setAgentName(res?.data?.agentId?.name);
      setDescription(res?.data?.description);
      setCoordinates({
        lat: res?.data?.address?.loc.coordinates[1],
        lng: res?.data?.address?.loc.coordinates[0],
      });
      // setState(res?.data?.address?.state);
      setCity(res?.data?.address?.city);
      setStreet(res?.data?.address?.road);
      // setCountry(res?.data?.address?.country)
      setPropertyType(res?.data?.propertyType);
      setReferenceNumber(res?.data?.referenceNumber);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const _getNextProperty = async (type) => {
    try {
      setLoader(true);
      const res = await getNextProperty(propertyId, type);
      setPropertyId(res.data._id);
      setPrice(res?.data?.price);
      setSqft(res?.data?.sqft);
      setBeds(res?.data?.beds);
      setBath(res?.data?.bath);
      setTenure(res?.data?.tenure);
      setVideoUrl(res?.data?.videoUrl);
      setVideoFile(
        res?.data?.videoUrl !== "" ? awsBaseUrl + res?.data?.videoUrl : ""
      );
      setAgentName(res?.data?.agentId?.name);
      setDescription(res?.data?.description);
      setCoordinates({
        lat: res?.data?.address?.loc.coordinates[1],
        lng: res?.data?.address?.loc.coordinates[0],
      });
      // setState(res?.data?.address?.state);
      setCity(res?.data?.address?.city);
      setStreet(res?.data?.address?.road);
      // setCountry(res?.data?.address?.country)
      setPropertyType(res?.data?.propertyType);
      setReferenceNumber(res?.data?.referenceNumber);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    _singleProperty();
  }, []);

  // useEffect(() => {
  //   if (progress == 100) {
  //     setLoader(false);
  //   }
  // }, [progress]);

  return (
    <div>
      {loader ? loadings : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Property</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/driver">Property</NavLink>
                </li>
                <li className="breadcrumb-item active">Detail</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className=" row mx-3 px-1">
        <div className="card card-info shadow-0 col-sm-12 px-0">
          <div
            className="card-header"
            style={{
              background: "#tranparent",
              flexDirection: "row",
              alignItems: "Center",
            }}
          >
            <h3 className="card-title">Detail</h3>
            <div className="card-tools ml-4">
              <div>
                <button
                  className="btn btn-light"
                  onClick={() => {
                    _getNextProperty("next");
                  }}
                >
                  Next{">>"}
                </button>
              </div>
            </div>
            <div className="card-tools">
              <div>
                <button
                  className="btn btn-light"
                  onClick={() => {
                    _getNextProperty("pre");
                  }}
                >
                  {"<<"} Previous
                </button>
              </div>
            </div>
          </div>

          <div className="row my-3">
            <div className="col-md-6">
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ width: "50%" }}>Price</th>
                      <td>{price}</td>
                    </tr>
                    <tr>
                      <th>Beds</th>
                      <td>{beds}</td>
                    </tr>
                    <tr>
                      <th>Tenure:</th>
                      <td>{tenure}</td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>{description}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>{propertyType}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6">
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ width: "50%" }}>Agent Name</th>
                      <td>{agentName}</td>
                    </tr>
                    {/* <tr>
                                            <th>Country</th>
                                            <td>{country}</td>
                                        </tr> */}
                    <tr>
                      <th>Address</th>
                      <td>
                        {street}
                        {`, ${city}`}
                      </td>
                    </tr>
                    {/* <tr>
                                            <th>City</th>
                                            <td>{city}</td>
                                        </tr> */}
                    <tr>
                      <th>Area(sqft)</th>
                      <td>{sqft}</td>
                    </tr>
                    <tr>
                      <th>Reference Number</th>
                      <td>{referenceNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 align-items-center d-flex pl-4 justify-content-center">
              <label>
                <h3>Preview</h3>
              </label>{" "}
            </div>
            <div className="form-group col-md-6">
              {videoFile === "" ? (
                "No video preview available"
              ) : (
                <div class="embed-responsive embed-responsive-16by9 ">
                  <iframe
                    class="embed-responsive-item"
                    src={videoFile}
                    allowfullscreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
