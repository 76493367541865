import React, { Suspense } from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import routes from "../routes";
import { loadings } from "../utlis/common";

export default function Content() {
  return (
    <div>
      <div className="content-wrapper">
        {/* <BrowserRouter> */}
        <Suspense fallback={loadings}>
          <Switch>
            {routes.map((route, id) => {
              return (
                route.component && (
                  <Route
                    key={id}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <route.component
                        {...props}
                        key={id}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                      />
                    )}
                  />
                )
              );
            })}
            <Redirect from="/" to="/home" />
          </Switch>
        </Suspense>
        {/* </BrowserRouter> */}
      </div>
    </div>
  );
}
