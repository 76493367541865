import qs from "qs";
import {
  makePostRequest,
  makeGetRequest,
  makePutRequest,
  makeDeleteRequest,
} from "./axiosBase";

export const addProperty = async (
  street,
  city,
  postCode,
  sqft,
  price,
  beds,
  bath,
  tenure,
  videoUrl,
  description,
  lat,
  lng,
  agentId,
  imageName,
  thumbnailName,
  propertyType,
  referenceNumber,
  isVideoUploaded,
  currency,
  address,
  heading,
  gamePrice,
  subHeading
) => {
  try {
    const data = {
      postCode: postCode,
      street: street,
      city: city,
      sqft: sqft,
      price: price,
      beds: beds,
      bath: bath,
      tenure: tenure,
      videoUrl: videoUrl,
      description: description,
      lat: lat,
      lng: lng,
      agentId: agentId,
      image: imageName,
      thumbnailName,
      propertyType,
      referenceNumber,
      isVideoUploaded,
      currency,
      address,
      heading,
      gamePrice,
      subHeading,
    };

    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/property/addPoperty",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const propertyList = async (limit, page) => {
  try {
    const params = {
      page,
      limit,
    };
    let res = await makeGetRequest("/admin/property/getProperty", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const blockProperty = async (userId) => {
  try {
    const config = { skipAuth: false };
    let res = await makePutRequest(`/admin/property/disable?_id=${userId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editProperty = async (
  _id,
  // country,
  postCode,
  street,
  city,
  // state,
  sqft,
  price,
  beds,
  bath,
  tenure,
  videoUrl,
  description,
  lat,
  lng,
  agentId,
  imageName,
  thumbnailName,
  propertyType,
  referenceNumber,
  address,
  currency,
  heading,
  gamePrice,
  subHeading
) => {
  try {
    
    const data = {
      _id: _id,
      // country: country,
      street: street,
      city: city,
      // state: state,
      postCode: postCode,
      sqft: sqft,
      price: price,
      beds: beds,
      bath: bath,
      tenure: tenure,
      videoUrl: videoUrl,
      description: description,
      lat: lat,
      lng: lng,
      agentId: agentId,
      image: imageName ? imageName : [],
      thumbnailName,
      propertyType,
      referenceNumber,
      address,
      currency,
      heading,
      gamePrice,
      subHeading,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      `/admin/property/editProperty`,
      qs.stringify(data),
      config,
      { crossDomain: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const singleProperty = async (id) => {
  try {
    const params = {
      _id: id,
    };
    let res = await makeGetRequest(`/admin/property/singleProperty`, params);

    return res.data;
  } catch (error) {
    console.log(error, "parteek");
    throw error;
  }
};

export const deleteProperty = async (id) => {
  try {
    const config = { skipAuth: false };
    let res = await makeDeleteRequest(
      `/admin/property/deleteProperty?_id=${id}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const changeOrder = async (data) => {
  try {
    data = {
      items: [
        ...data.map((item) => {
          return { order: item.order, _id: item._id };
        }),
      ],
    };
    console.log();
    const config = { skipAuth: false };
    let res = await makePutRequest(
      "/admin/property/changeOrder",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const videoUploaded = async (videoUrl) => {
  try {
    const data = { videoUrl };
    const config = { skipAuth: false };
    let res = await makePutRequest(
      "/admin/property/videoUploaded",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getNextProperty = async (id, type) => {
  try {
    const params = {
      propertyId: id,
      type,
    };
    let res = await makeGetRequest(`/admin/property/nextProperty`, params);

    return res.data;
  } catch (error) {
    console.log(error, "parteek");
    throw error;
  }
};
