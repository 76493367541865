import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FiPackage } from "react-icons/fi";
import { agentDetail, editAgent } from "../../networkRequests/agents";
import {
  toastError,
  toastSuccess,
  loadings,
  deliveryStatus,
} from "../../utlis/common";
import { v4 as uuidv4 } from "uuid";
import { awsBaseUrl } from "../../utlis/appConstants";
import { CircularProgress } from "@mui/material";
import { useUploadForm } from "./../../utlis/s3Upload";
import { useHistory } from "react-router-dom";

export default function EditAgent(props) {
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const { isSuccess, uploadFile, progress } = useUploadForm();

  const handleImageChange = async (e) => {
    e.preventDefault();
    setLoader(true);
    console.log("hiiiii", e.target.files[0]);
    let file = e.target.files[0];
    const fileName =
      file.name.split(".")[0] +
      "_" +
      uuidv4() +
      "." +
      file.name.split(".").pop();
    setImageName(fileName);
    setImage(URL.createObjectURL(file));
    const contentType = file.type;
    uploadFile(file, fileName, contentType)
      .then((data) => {
        if (progress == 100) {
          setLoader(false);
        }
      })
      .catch((err) => {
        return toastError(
          "There is error in uploadings an image please try after some time"
        );
      });
  };

  const _editAgent = async (e) => {
    e.preventDefault();
    if (loader) {
      return toastError("File is uploading");
    }
    // if (phone) {
    //   if (isNaN(phone) || phone.length > 10 || phone.length < 10) {
    //     return toastError("Please enter a valide phone number");
    //   }
    // }
    // if (!countryCode) {
    //   return toastError("Please enter country Code");
    // }
    // if (!description) {
    //   return toastError("Please enter description");
    // }
    // if (!imageName) {
    //   return toastError("Please add an image");
    // }

    // if (!name || !phone || !countryCode || !description || !imageName) {
    //   return toastError("Please fill all fields");
    // }

    try {
      setLoader(true);
      const res = await editAgent(
        props.location.state,
        name,
        description,
        email,
        imageName
      );
      toastSuccess(res.message);
      setLoader(false);
      history.push("/agentListings");
      //  document.getElementById("submit").reset();
    } catch (error) {
      console.log("kkkkkkkkk", error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const _agentDetail = async () => {
    try {
      setLoader(true);
      const res = await agentDetail(props.location.state);
      setDescription(res?.data?.description || "");
      setEmail(res?.data?.email);
      setName(res?.data?.name);
      setImageName(res?.data?.imageUrl || "");
      setImage(awsBaseUrl + res?.data?.imageUrl);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    _agentDetail();
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setLoader(false);
    }
  }, [progress]);
  return (
    <div>
      {loader ? loadings : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Agent</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/addAgent">Agent</NavLink>
                </li>
                <li className="breadcrumb-item active">Edit</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 px-8">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Edit Agent</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label for="inputProjectLeader">Profile Image</label>
                    <div>
                      <img
                        // height={"30%"}
                        // width={"30%"}
                        class="profile-user-img img-fluid "
                        src={image}
                        alt="User profile picture"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="exampleInputFile">Change Image</label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          singleFile={true}
                          className="form-control"
                          onChange={handleImageChange}
                        />
                        {loader ? <CircularProgress value={progress} /> : ""}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="inputName">Agent Name</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      value={name || ""}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label for="inputDescription">Agent Description</label>
                    <textarea
                      id="inputDescription"
                      className="form-control"
                      rows="4"
                      value={description || ""}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label for="inputClientCompany">Email</label>
                    <input
                      type="text"
                      id="inputClientCompany"
                      className="form-control"
                      value={email || ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className="btn  mr-3"
                      onClick={_editAgent}
                      style={{ background: "#17a2b8", color: "white" }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
