
export const baseUrl = "https://api.mywonderland.net";
//export const baseUrl = "http://localhost:5000"

export const awsBaseUrl = "https://mywonderland.s3.eu-north-1.amazonaws.com/";

export const userPerPage = 10;

export const openModel = {
  ASSIGN_DRIVER: 1,
  DRIVER_LOC: 3,
  NON: 2,
};

