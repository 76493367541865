import react, { useState } from "react";
import AWS from "aws-sdk";
import { videoUploaded } from "../networkRequests/property";
import { useLoading } from "../context/loadingContext";

const S3_BUCKET = "mywonderland";
const REGION = "eu-north-1";
const COGNITO_POOL_ID = "eu-north-1:2f7c33cf-a067-427d-9c51-2676d8f24a0c";

AWS.config.update({
  region: REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: COGNITO_POOL_ID,
  }),
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const useUploadForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const { loadingState, setLoadingState } = useLoading();

  const uploadFile = async (
    file,
    fileName,
    contentType,
    isVideoUpload,
    referenceNumber
  ) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      ContentType: contentType,
      Key: fileName,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        if (Math.round((evt.loaded / evt.total) * 100) === 100) {
          setIsSuccess(true);
          setProgress(0);
          if (isVideoUpload) {
            setIsVideoUploaded(true);
            try {
              videoUploaded(fileName);
              setLoadingState([
                ...loadingState.filter((ref) => ref != fileName),
              ]);
            } catch (error) {
              console.log(error);
            }
            console.log(
              "DONE",
              ":fdbkjhfgk;hj;kljfg;klhj;klfgj;klhj;klfjg;klhj;klfgjhkl"
            );
          }
        }
      })
      .send((err) => {
        if (err) console.log("errrrrororor", err);
      });
  };

  return {
    uploadFile,
    isSuccess,
    progress,
    isVideoUploaded,
    setIsVideoUploaded,
  };
};

// export default {useUploadForm};
