import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toastError, toastSuccess, loading } from "../../utlis/common";
import { agentList, deleteAgent } from "../../networkRequests/agents";
import { userPerPage } from "../../utlis/appConstants";
import ContentLoader, { Code } from "react-content-loader";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { margin, padding } from "@mui/system";

const MyLoader = () => <Code />;

export default function Agents() {
  const history = useHistory();
  const [pageNumber, setpageNumber] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [agents, setAgents] = useState([]);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState("");
  const pageCount = Math.ceil(userCount / userPerPage);

  const _agentList = async () => {
    try {
      setLoader(true);
      const res = await agentList(userPerPage, pageNumber);
      setAgents(res.data.agents);
      setUserCount(res.data.count);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error.message);
    }
  };

  const _deleteAgent = async (userId) => {
    try {
      setLoader(true);
      const res = await deleteAgent(userId);
      toastSuccess(res.message);
      setModal(false);
      setPageChange(!pageChange);
      setpageNumber(0);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    _agentList();
  }, [pageChange]);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Agent</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to="/addAgent">Agent</NavLink>
                </li>
                <li className="breadcrumb-item active">Listings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <Modal
          open={modal}
          onClose={() => {
            setModal(false);
          }}
        >
          <h2>Delete Agent</h2>
          <p>Are you sure want to delete agent .</p>
          <div className="col d-flex justify-content-between ">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setModal(false);
              }}
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                _deleteAgent(id);
              }}
            >
              Delete
            </button>
          </div>
        </Modal>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div
                  className="card-header"
                  style={{ background: "#tranparent" }}
                >
                  <h3 className="card-title">Listings</h3>
                  <div className="card-tools">
                    <div>
                      <button
                        className="btn  mr-3"
                        onClick={() => {
                          history.push({
                            pathname: "/addAgent",
                          });
                        }}
                        style={{ background: "#fff", color: "black" }}
                      >
                        Add Agent
                      </button>
                    </div>
                  </div>
                </div>
                {loader ? (
                  <MyLoader />
                ) : (
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th> Name</th>
                          <th>Email</th>
                          <th>createdAt</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agents.map((m) => (
                          <tr key={m?._id}>
                            <td>{m?.name}</td>
                            <td>{m?.email}</td>
                            <td>{moment(m?.createdAt).format("MM/DD/YYYY")}</td>
                            <td>
                              <i
                                className="nav-icon fas"
                                type="button"
                                title="view"
                                size="2.5em"
                                onClick={() => {
                                  history.push({
                                    pathname: "/singleAgent",
                                    state: m._id,
                                  });
                                }}
                              >
                                <AiOutlineEye />
                              </i>
                              &nbsp;&nbsp;
                              <i
                                className="nav-icon fas "
                                type="button"
                                title="Delete"
                                // onClick={() => {
                                //     _deleteAgent(m?._id);
                                // }}
                                onClick={() => {
                                  setModal(true);
                                  setId(m?._id);
                                }}
                              >
                                <AiOutlineDelete />
                              </i>
                              &nbsp;&nbsp;
                              <i
                                className="nav-icon fas"
                                type="button"
                                title="view"
                                size="2.5em"
                                onClick={() => {
                                  history.push({
                                    pathname: "/editAgent",
                                    state: m._id,
                                  });
                                }}
                              >
                                <BiEditAlt />
                              </i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {agents?.length === 0 && (
                  <div className="justify-content-center d-flex my-5">
                    No Data
                  </div>
                )}
                <div>
                  {/* {userCount >= 10 ? ( */}
                  <div className="card-footer clearfix d-flex justify-content-center pt-3">
                    <ReactPaginate
                      previousLabel={<span aria-hidden="true">&laquo;</span>}
                      nextLabel={<span aria-hidden="true">&raquo;</span>}
                      breakLabel={"....."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      onPageChange={(selected) => {
                        setpageNumber(selected.selected);
                        setPageChange(!pageChange);
                      }}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"page-item active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
