import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FiPackage } from "react-icons/fi";
import { agentDetail } from "../../networkRequests/agents";
import {
    toastError,
    toastSuccess,
    loadings,
    deliveryStatus,
} from "../../utlis/common";
import { awsBaseUrl } from "../../utlis/appConstants"

export default function Detail(props) {
    const [agent, setAgent] = useState({});
    const [loader, setLoader] = useState(false);

    const _agentDetail = async () => {
        try {
            setLoader(true);

            console.log("11111111111", props.location.state)
            const res = await agentDetail(props.location.state);
            console.log("ress", res)
            setAgent(res.data);
            setLoader(false);
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    };
    
    useEffect(() => {
        _agentDetail();
    }, []);

    return (
        <div>
            {loader ? loadings : ""}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Agent</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li className="breadcrumb-item active">
                                    <NavLink to="/addAgent">Agent</NavLink>
                                </li>
                                <li className="breadcrumb-item active">Detail</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-8">
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Detail</h3>
                                </div>






                                <div className="card-body">

                                    <div className="form-group">
                                        <label for="inputProjectLeader">Image</label>
                                        <div >
                                            <img class="profile-user-img" src={awsBaseUrl + agent?.imageUrl} alt="User profile picture" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputName">Agent Name</label>
                                        <input type="text" id="inputName" className="form-control" value={agent?.name || ""} />
                                    </div>
                                    <div className="form-group">
                                        <label for="inputDescription">Agent Description</label>
                                        <textarea id="inputDescription" className="form-control" rows="4" value={agent?.description || ""}></textarea>
                                    </div>
                                    {/* <div className="form-group">
                                        <label for="inputStatus">Status</label>
                                        <select id="inputStatus" className="form-control custom-select">
                                            <option disabled>Select one</option>
                                            <option>On Hold</option>
                                            <option>Canceled</option>
                                            <option selected>Success</option>
                                        </select>
                                    </div> */}
                                    <div className="form-group">
                                        <label for="inputClientCompany">Phone Number</label>
                                        <input type="text" id="inputClientCompany" className="form-control" value={(agent?.countryCode || "") + "" + (agent?.phoneNumber || "")} />
                                    </div>
                                    {/* <div>
                                        <button
                                            className="btn  mr-3"
                                            onClick={_editAgent}
                                            style={{ background: "#17a2b8", color: "white" }}
                                        >
                                            Edit
                                        </button>

                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
