import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { addProperty } from "../../networkRequests/property";
import { toastError, toastSuccess, loadings } from "../../utlis/common";
import { agentList } from "../../networkRequests/agents";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { AiOutlineDelete } from "react-icons/ai";

import { LinearProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useUploadForm } from "./../../utlis/s3Upload";
import { CircularProgress } from "@mui/material";
import { useLoading } from "../../context/loadingContext";

export default function Add() {
  // const [country, setCountry] = useState("");
  const { loadingState, setLoadingState } = useLoading();
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [preview, setPreview] = useState([]);
  // const [state, setState] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [sqft, setSqft] = useState("");
  const [price, setPrice] = useState("");
  const [beds, setBeds] = useState("");
  const [bath, setBath] = useState("");
  const [tenure, setTenure] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailName, setThumbnailName] = useState("");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const [address, setAddress] = useState("");
  const [agentId, setAgentId] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [agents, setAgents] = useState([]);
  const {
    isSuccess,
    uploadFile,
    progress,
    isVideoUploaded,
    setIsVideoUploaded,
  } = useUploadForm();
  const [image, setImage] = useState([]);
  const [imageName, setImageName] = useState([]);
  const [arrayofFiles, setArrayofFiles] = useState([]);
  const [propertyType, setPropertyType] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [heading, setHeading] = useState("");
  const [gamePrice, setGamePrice] = useState("");
  const [subHeading, setSubHeading] = useState("");

  const _addProperty = async (e) => {
    e.preventDefault();
    await _propertyAdding();
  };

  const _propertyAdding = async () => {
    if (videoUrl && !thumbnailName) {
      return toastError("Please select thumbnail for image");
    }
    // if (!price) {
    //   return toastError("Please enter price");
    // }

    if (!agentId) {
      return toastError("Agent is not selected");
    }

    try {
      setLoader(true);
      const res = await addProperty(
        street,
        city,
        postCode,
        sqft,
        price,
        beds,
        bath,
        tenure,
        videoUrl,
        description,
        coordinates.lat,
        coordinates.lng,
        agentId,
        imageName,
        thumbnailName,
        propertyType,
        referenceNumber,
        isVideoUploaded,
        currency,
        address,
        heading,
        gamePrice,
        subHeading
      );
      setSubHeading("");
      setCurrency("");
      setHeading("");
      toastSuccess(res.message);
      setVideoUrl("");
      setVideoFile("");
      setImage("");
      setImageName([]);
      setAddress("");
      setDescription("");
      setTenure("");
      setCity("");
      setPostCode("");
      setBath("");
      setBeds("");
      setReferenceNumber("");
      setPropertyType("");
      setSqft("");
      setStreet("");
      setGamePrice("");
      //document.getElementById("submit").reset();
      setPreview([]);
      setIsVideoUploaded(false);
      setThumbnailName("");
      setThumbnailPreview("");
      setPrice("");
      setLoader(false);
    } catch (error) {
      console.log("hhhhhhhhhhhhh", error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const _agentList = async () => {
    try {
      const res = await agentList(10000, 0);

      setAgents(res.data.agents);
    } catch (error) {
      console.log(error.message);
    }
  };

  let handleChange = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    const fileName = (
      file.name.split(".")[0] +
      "_" +
      uuidv4() +
      "." +
      file.name.split(".").pop()
    ).replace(/\s/g, "");
    // const thumbnailNametoBeSave = uuidv4() + ".jpeg";
    setVideoUrl(fileName);
    // setThumbnailName(thumbnailNametoBeSave);
    const contentType = file.type;
    setVideoFile(URL.createObjectURL(file));
    // const thumbnail = await genrateThumbnail(URL.createObjectURL(file));
    // await uploadFile(thumbnail, thumbnailNametoBeSave);
    if (!loadingState.includes(referenceNumber)) {
      setLoadingState([...loadingState, referenceNumber]);
    }

    uploadFile(file, fileName, contentType, true, referenceNumber)
      .then((data) => {})
      .catch((err) => {
        return toastError(
          "There is error in uploading an file please try after some time"
        );
      });
  };

  const handleSelect = async (value) => {
    setAddress(value);
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setCoordinates(ll);
  };

  const handleImageChange = async () => {
    let arrayImages = [];
    for (var i = 0; i < arrayofFiles?.length; i++) {
      // setLoader(true);
      let file = arrayofFiles[i];
      const fileName = (
        file.name.split(".")[i] +
        "_" +
        uuidv4() +
        "." +
        file.name.split(".").pop()
      ).replace(/\s/g, "");

      arrayImages.push(fileName);

      // setImageName((pevState) => [...pevState,...fileName]);
      // setImageName(...imageName, ...fileName);
      // setImage(URL.createObjectURL(file));
      const contentType = file.type;

      uploadFile(file, fileName, contentType)
        .then((data) => {
          // console.log("ooooo", imageName)
        })
        .catch((err) => {
          return toastError(
            "There is error in uploading an image please try after some time"
          );
        });
    }
    setImageName(arrayImages);
    // if (arrayImages.length == 0) {
    //   return toastError("Thumnail Image must not be empty");
    // }
  };

  const onSelectFile = (e) => {
    for (var i = 0; i < e.target.files.length; i++) {
      console.log(e.target.files[i]);
      arrayofFiles.push(e.target.files[i]);
    }

    let images = [];
    arrayofFiles.map((e) => {
      const ImageUrl = URL.createObjectURL(e);
      images.push(ImageUrl);
    });

    setPreview(images);
  };

  const removeImageFromArray = (e) => {
    const index = e.target.id;
    let newPreview = [...preview];
    let newArrofFiles = [...arrayofFiles];
    newPreview.splice(index, 1);
    newArrofFiles.splice(index, 1);
    setPreview(newPreview);
    setArrayofFiles(newArrofFiles);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnailPreview(URL.createObjectURL(file));

    const fileName = (
      file.name.split(".") +
      "_" +
      uuidv4() +
      "." +
      file.name.split(".").pop()
    ).replace(/\s/g, "");
    setThumbnailName(fileName);

    uploadFile(file, fileName, file.type)
      .then((data) => {})
      .catch((err) => {
        return toastError(
          "There is error in uploading an file please try after some time"
        );
      });
  };

  useEffect(() => {
    _agentList();
  }, []);
  useEffect(() => {
    handleImageChange();
  }, [preview]);

  useEffect(() => {
    if (progress == 100) {
      setLoader(false);
    }
  }, [progress]);

  // useEffect(() => {
  //  if (imageName.length === arrayofFiles?.length && imageName.length !== 0) {
  // _propertyAdding();
  // }
  // }, [imageName]);

  return (
    <div>
      {loader ? loadings : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Property</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/driver">Property</NavLink>
                </li>
                <li className="breadcrumb-item active">Add</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className=" row mx-3 px-1">
        <div className="card card-info shadow-0 col-sm-12 px-0">
          <div
            className="card-header "
            // style={{ backgroundColor: "#F46C03" }}
          >
            <h6 className=" my-2">Add</h6>
          </div>

          {/* <div className=" row ml-3 px-1">
        <div className="card shadow-0 col-sm-12 px-6">
          <div
            className="card-header "
          // style={{ backgroundColor: "#5CC0B9" }}
          >
            <h6 className=" my-2">Add Agent</h6>
          </div> */}

          <form id="submit">
            <div className="card-body">
              <div className="form-group">
                <label>Agents</label>
                <select
                  value={agentId}
                  className="custom-select"
                  scrollable="true"
                  onChange={(event) => setAgentId(event.target.value)}
                >
                  <option value="" disabled selected hidden>
                    select agent
                  </option>
                  {agents?.map((item, idx) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                  {/* {users.map((user) => {
                    return <option value={user._id}>{user.name}</option>;
                  })} */}
                </select>
              </div>
              <div className="form-group">
                <label>Type</label>
                <select
                  value={propertyType}
                  className="custom-select"
                  scrollable="true"
                  onChange={(event) => setPropertyType(event.target.value)}
                >
                  <option value="" disabled selected hidden>
                    select property type
                  </option>

                  <option value={"FOR SALE"}>FOR SALE</option>
                  <option value={"SOLD"}>SOLD</option>
                  <option value={"INFORMATION"}>INFORMATION</option>
                  <option value={"INSPIRATION"}>INSPIRATION</option>
                  <option value={"ADVERTISMENT"}>ADVERTISMENT</option>
                  <option value={"FOR RENT"}>FOR RENT</option>
                </select>
              </div>
              <div className="form-group">
                <label>Reference Number</label>
                <input
                  //type="password"
                  // required
                  value={referenceNumber}
                  autofocus
                  className="form-control"
                  placeholder="Reference Number"
                  onChange={(e) => {
                    setReferenceNumber(e.target.value);
                  }}
                />
              </div>

              <div className="form-group ">
                <label>Price</label>
                <input
                  //type="password"
                  // required
                  autofocus
                  value={price}
                  className="form-control"
                  placeholder="Price"
                  onChange={(e) => {
                    setPrice(e.target.value.replace(/\D/g, ""));
                  }}
                />
              </div>
              <div className="form-group ">
                <label>Currency</label>
                <input
                  //type="password"
                  // required
                  autofocus
                  value={currency}
                  className="form-control"
                  placeholder="currency"
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <label>Heading</label>
                <input
                  //type="password"
                  // required
                  value={heading}
                  autofocus
                  className="form-control"
                  placeholder="heading"
                  onChange={(e) => {
                    setHeading(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Sub Heading</label>
                <input
                  //type="password"
                  // required
                  value={subHeading}
                  autofocus
                  className="form-control"
                  placeholder="sub heading"
                  onChange={(e) => {
                    setSubHeading(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Beds</label>
                <input
                  // type="number"
                  // required
                  value={beds}
                  autofocus
                  className="form-control"
                  placeholder="Beds"
                  onChange={(e) => {
                    setBeds(e.target.value.replace(/\D/g, ""));
                  }}
                />
              </div>
              <div className="form-group">
                <label>Bath</label>
                <input
                  //type="password"
                  // required
                  value={bath}
                  autofocus
                  className="form-control"
                  placeholder="Bath"
                  onChange={(e) => {
                    setBath(e.target.value.replace(/\D/g, ""));
                  }}
                />
              </div>
              <div className="form-group">
                <label> Area(sqft) </label>
                <input
                  // type="number"
                  // required
                  value={sqft}
                  autofocus
                  className="form-control"
                  placeholder="Area(sqft)"
                  onChange={(e) => {
                    setSqft(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Road</label>
                <input
                  // type="number"
                  // required
                  value={street}
                  autofocus
                  className="form-control"
                  placeholder="Street"
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>PostCode</label>
                <input
                  // type="number"
                  // required
                  value={postCode}
                  autofocus
                  className="form-control"
                  placeholder="postCode"
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>City</label>
                <input
                  // type="number"
                  // required
                  value={city}
                  autofocus
                  className="form-control"
                  placeholder="City"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Tenure</label>
                <input
                  // type="number"
                  // required
                  value={tenure}
                  autofocus
                  className="form-control"
                  placeholder="Leasehold"
                  onChange={(e) => {
                    setTenure(e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  //type="password"
                  // required
                  value={description}
                  rows={10}
                  autofocus
                  className="form-control"
                  placeholder="Description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <div>
                  <PlacesAutocomplete
                    value={address}
                    onChange={(address) => setAddress(address)}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Location On Map ",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {/* {errors.address && (
                              <p className="text-danger">{errors.address}</p>
                            )} */}
                </div>
              </div>

              <div className="form-group">
                {preview.map((img, index) => (
                  <div key={index}>
                    <img
                      class="profile-user-img img-fluid "
                      src={img}
                      id={index}
                      alt="loading..."
                    />

                    <i
                      id={index}
                      key={index}
                      title="Delete"
                      onClick={(e) => {
                        removeImageFromArray(e);
                      }}
                    >
                      <AiOutlineDelete />
                    </i>
                    {/* <button
                      id={index}
                      key={index}
                      onClick={(e) => {
                        removeImageFromArray(e);
                      }}
                    /> */}
                  </div>
                ))}
              </div>
              <div className="form-group">
                <label for="exampleInputFile">Pictures</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      name="file"
                      multiple
                      onChange={onSelectFile}
                      accept="image/*"
                      className="form-control"
                    />
                    {loader ? <CircularProgress value={progress} /> : ""}
                  </div>
                </div>
              </div>

              <div className="form-group">
                {videoFile === "" ? (
                  ""
                ) : (
                  <div class="embed-responsive embed-responsive-16by9 w-50">
                    <iframe
                      class="embed-responsive-item"
                      src={videoFile}
                      allowfullscreen
                    ></iframe>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label for="exampleInputFile">Video</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      singleFile={true}
                      className="form-control"
                      accept="video/*"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                    {loader ? <CircularProgress value={progress} /> : ""}
                    {/* <LinearProgress variant="determinate" value={progress} /> */}
                  </div>
                </div>
              </div>
              {thumbnailPreview && (
                <div className="form-group">
                  <div>
                    <img
                      class="profile-user-img img-fluid "
                      src={thumbnailPreview}
                      // id={index}
                      alt="loading..."
                    />
                  </div>
                </div>
              )}

              <div className="form-group">
                <label for="exampleInputFile">Video Thumbnail</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      singleFile={true}
                      className="form-control"
                      accept="image/*"
                      onChange={(event) => {
                        handleThumbnailChange(event);
                      }}
                    />
                    {loader ? <CircularProgress value={progress} /> : ""}
                  </div>
                </div>
              </div>

              <div>
                <button type="reset" className="btn btn-secondary">
                  Reset
                </button>
                {"  "}
                <button
                  // type="submit"
                  className="btn  mr-3"
                  onClick={_addProperty}
                  style={{ background: "#17a2b8", color: "white" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
