import React from "react";
import agent from "./pages/agent/AddAgent";
import agentListings from "./pages/agent/listings";
import singleAgent from "./pages/agent/singleAgent";
import EditAgent from "./pages/agent/editAgent";

import feedbackListings from "./pages/feedback/listing";
import addProperty from "./pages/Property/addProperty";
import editProperty from "./pages/Property/editProperty";
import propertyListing from "./pages/Property/listings";
import singleProperty from "./pages/Property/singleProperty";
import DragProperty from "./pages/Property/DragProperty";
const User = React.lazy(() => import("./pages/user/UserList"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));

const routes = [
  { path: "/home", exact: true, name: "", component: Dashboard },
  {
    path: "/changePassword",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/user",
    exact: true,
    name: "User",
    component: User,
  },
  {
    path: "/addAgent",
    exact: true,
    name: "Add Agent",
    component: agent,
  },
  {
    path: "/agentListings",
    exact: true,
    name: "Agent Listings",
    component: agentListings,
  },
  {
    path: "/singleAgent",
    exact: true,
    name: "Single Agent",
    component: singleAgent,
  },
  {
    path: "/editAgent",
    exact: true,
    name: "Edit Agent",
    component: EditAgent,
  },
  {
    path: "/feedBack",
    exact: true,
    name: "feedBack listing",
    component: feedbackListings,
  },
  {
    path: "/addProperty",
    exact: true,
    name: "Property",
    component: addProperty,
  },
  {
    path: "/propertyListing",
    exact: true,
    name: "property Listing",
    component: propertyListing,
  },
  {
    path: "/editProperty",
    exact: true,
    name: "Edit Property",
    component: editProperty,
  },
  {
    path: "/singleProperty",
    exact: true,
    name: "Single Property",
    component: singleProperty,
  },
  {
    path: "/dragProperty",
    exact: true,
    name: "drag Property",
    component: DragProperty,
  },
];

export default routes;
