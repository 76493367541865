import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { propertyList } from "../../networkRequests/property";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

export default function DragProperty() {
  const [property, setProperty] = useState([]);
  const [loader, setLoader] = useState(false);

  console.log(property);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...property];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setProperty(updatedList);
  };

  const _propertyList = async () => {
    try {
      setLoader(true);
      const res = await propertyList(100, 0);

      setProperty(res.data.properties);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    _propertyList();
  }, []);

  return (
    <div className="App">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Property</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to="/addProperty">Property</NavLink>
                </li>
                <li className="breadcrumb-item active">Listings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body table-responsive p-0">
        <DragDropContext onDragEnd={handleDrop}>
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Address</th>
                <th>Postcode</th>
                <th>Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <Droppable droppableId="droppable-1">
              {(provider) => (
                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                  {property?.map((user, index) => (
                    <Draggable
                      key={user._id}
                      draggableId={user._id}
                      index={index}
                    >
                      {(provider) => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                        >
                          <td {...provider.dragHandleProps}>
                            {user?.propertyType}
                          </td>
                          <td {...provider.dragHandleProps}>
                            {moment(user?.createdAt).format("MM/DD/YYYY")}
                          </td>

                          <td {...provider.dragHandleProps}>
                            {user?.address?.road}
                            {user?.address?.city}
                          </td>
                          <td {...provider.dragHandleProps}>
                            {user?.address?.postCode}
                          </td>
                          <td {...provider.dragHandleProps}>{user?.price}</td>
                          {/* <td>{m?.referenceNumber}</td> */}

                          <td {...provider.dragHandleProps}>
                            {user?.isBlocked ? (
                              <span className="badge bg-danger">Blocked</span>
                            ) : (
                              <span className="badge bg-success">Active</span>
                            )}
                          </td>
                          <td>
                            <i
                              className="nav-icon fas"
                              type="button"
                              title="view"
                              size="2.5em"
                              onClick={(e) => {
                                console.log("ekkkkkk");
                                // history.push({
                                //   pathname: "/singleProperty",
                                //   state: user._id,
                                // });
                              }}
                            >
                              <AiOutlineEye />
                            </i>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
    </div>
  );
}
