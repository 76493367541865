import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import { createBrowserHistory } from "history";
import Layout from "./components/Layout";
import { loadings } from "./utlis/common";
import { LoadingContext } from "./context/loadingContext";

import "./css/app.css";

const history = createBrowserHistory();

function App() {
  let auth = localStorage.accessToken;
  const [loadingState, setLoadingState] = useState([]);

  const changeLoadingState = (data) => {
    setLoadingState(data);
  };
  console.log({ loadingState });

  return (
    <BrowserRouter>
      <Suspense fallback={loadings}>
        <Switch>
          <Switch>
            {!auth && (
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
            )}
            {!auth && (
              <Route
                render={() => {
                  history.push({ pathname: "/login" });
                  history.go("/login");
                }}
              />
            )}
            <LoadingContext.Provider
              value={{ loadingState, setLoadingState: changeLoadingState }}
            >
              {auth && (
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <Layout {...props} />}
                />
              )}
            </LoadingContext.Provider>
          </Switch>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
