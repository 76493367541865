import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

import { toastError, toastSuccess, loading } from "../../utlis/common";
import { feedBackList, editStatus } from "../../networkRequests/feedBack";
import { userPerPage } from "../../utlis/appConstants";
import ContentLoader, { Code } from "react-content-loader";

const MyLoader = () => <Code />;

export default function Feedback() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [pageNumber, setpageNumber] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [selected, setSelected] = useState("name");
  const pageCount = Math.ceil(userCount / userPerPage);

  const sorting = (col) => {
    if (order === "ASC" || order === null) {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("ASC");
    }
  };

  const _feedBackList = async () => {
    try {
      setLoader(true);
      const res = await feedBackList(userPerPage, pageNumber);
      setUsers(res.data.feedback);
      setUserCount(res.data.count);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error.message);
    }
  };

  const _editStatus = async (userId, status) => {
    try {
      setLoader(true);
      const res = await editStatus(userId, status);
      toastSuccess(res.message);
      setPageChange(!pageChange);
      setpageNumber(0);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    _feedBackList();
  }, [pageChange]);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Feedback</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">Feedback</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div
                  className="card-header"
                  style={{ background: "#tranparent" }}
                >
                  <h3 className="card-title">Listings</h3>
                </div>
                {loader ? (
                  <MyLoader />
                ) : (
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>User Name</th>
                          <th>User Email</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((m) => (
                          <tr>
                            <td>{m?.userId?.name}</td>
                            <td>{m?.userId?.email}</td>
                            <td title={m?.description}>
                              {m?.description?.length >= 20
                                ? m?.description.substring(0, 20) + "..."
                                : m?.description}
                            </td>
                            <td>{m?.status}</td>
                            <td>
                              <select
                                style={{
                                  background: "#5CC0B9",
                                  color: "white",
                                }}
                                name="navyOp"
                                className="btn "
                                onChange={(e) =>
                                  _editStatus(m?._id, e.target.value)
                                }
                              >
                                <option
                                  disabled="disabled"
                                  selected="selected"
                                  value="undefined"
                                  hidden
                                >
                                  {"change Status"}
                                </option>
                                <option value="Pending">Pending</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Rejected">Rejected</option>
                              </select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {users?.length === 0 && (
                  <div className="justify-content-center d-flex my-5">
                    No Data
                  </div>
                )}
                <div>
                  {/* {userCount >= 10 ? ( */}
                  <div className="card-footer clearfix d-flex justify-content-center pt-3">
                    <ReactPaginate
                      previousLabel={<span aria-hidden="true">&laquo;</span>}
                      nextLabel={<span aria-hidden="true">&raquo;</span>}
                      breakLabel={"....."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      onPageChange={(selected) => {
                        setpageNumber(selected.selected);
                        setPageChange(!pageChange);
                      }}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"page-item active"}
                    />
                  </div>
                  {/* ) : (
                                        ""
                                    )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
