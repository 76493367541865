import qs from "qs";

import { makePostRequest, makeGetRequest, makePutRequest } from "./axiosBase";

export const addAgent = async (name, description, email, imageName) => {
  try {
    const data = {
      name: name,
      imageUrl: imageName,
      description: description,
      email,
    };
    const config = { skipAuth: false };

    let res = await makePostRequest(
      "/admin/agent/addAgents",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const agentList = async (limit, page) => {
  try {
    const params = {
      page,
      limit,
    };
    let res = await makeGetRequest("/admin/agent/getAgents", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const agentDetail = async (id) => {
  try {
    const params = {
      id: id,
    };
    let res = await makeGetRequest(`/admin/agent/singleAgent`, params);
    console.log("result", res);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteAgent = async (userId) => {
  try {
    const config = { skipAuth: false };
    let res = await makePutRequest(`/admin/agent/deleteAgent?_id=${userId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editAgent = async (id, name, description, email, imageName) => {
  try {
    const data = {
      id: id,
      name: name,
      imageUrl: imageName,
      description: description,
      email,
    };
    const config = { skipAuth: false };

    let res = await makePostRequest(
      `/admin/agent/editAgent`,
      qs.stringify(data),
      config,
      { crossDomain: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
