import qs from "qs";

import { makePostRequest, makeGetRequest, makePutRequest } from "./axiosBase";

export const feedBackList = async (limit, page) => {
  try {
    const params = {
      page,
      limit
    };
    let res = await makeGetRequest("/admin/feedback/getFeedback", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editStatus = async (_id, status) => {
    try {
      const config = { skipAuth: false };
    //   const path = `${instructor ? "&instructor=" + instructor : ""}${
    //     startDate ? "&startDate=" + startDate : ""
    //   }${endDate ? "&endDate=" + endDate : ""}${
    //     status ? "&status=" + status : ""
    //   }`;
      let res = await makePutRequest(
        `/admin/feedback/editFeedback?id=${_id}&status=${status}`,
        {},
        config,
        { crossDomain: true }
      );
  
      return res.data;
    } catch (error) {
      throw error;
    }
  };
