import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { BiBlock } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import moment from "moment";
import { toastError, toastSuccess, loadings } from "../../utlis/common";
import {
  propertyList,
  blockProperty,
  deleteProperty,
  changeOrder,
} from "../../networkRequests/property";

import ContentLoader, { Code } from "react-content-loader";

const MyLoader = () => <Code />;

export default function Agents() {
  const history = useHistory();
  const [pageNumber, setpageNumber] = useState(0);
  const [propertyCount, setPropertyCount] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [property, setProperty] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [userPerPage, setUserPerPage] = useState(1000);
  const [orderChanged, setOrderChanged] = useState(false);
  const [loaderPC, setLoaderPC] = useState(false);

  const pageCount = Math.ceil(propertyCount / userPerPage);

  const _propertyList = async () => {
    try {
      setLoader(true);
      const res = await propertyList(userPerPage, pageNumber);
      setProperty(res.data.properties);
      setPropertyCount(res.data.total);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error.message);
    }
  };

  const _blockProperty = async (userId) => {
    try {
      setLoader(true);
      const res = await blockProperty(userId);
      toastSuccess(res.message);
      setPageChange(!pageChange);
      setpageNumber(0);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const _deleteProperty = async () => {
    try {
      setLoader(true);
      await deleteProperty(id);
      toastSuccess("Success");
      setModal(false);
      setPageChange(!pageChange);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...property];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    updatedList.map((data, index) => (data.order = index));
    setOrderChanged(true);
    setProperty(updatedList);
  };

  const _changeOrder = async () => {
    try {
      setLoaderPC(true);
      const res = await changeOrder(property);
      setLoaderPC(false);
      setOrderChanged(false);
    } catch (error) {
      console.log(error);
      setLoaderPC(false);
    }
  };

  useEffect(() => {
    _propertyList();
  }, [pageChange, userPerPage]);

  return (
    <div>
      {loaderPC && loadings}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Property</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to="/addProperty">Property</NavLink>
                </li>
                <li className="breadcrumb-item active">Listings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div
                  className="card-header"
                  style={{ background: "#tranparent" }}
                >
                  <h3 className="card-title">Listings</h3>
                  <div className="card-tools">
                    <div>
                      {orderChanged && (
                        <button
                          className="btn btn-light mr-3"
                          onClick={() => {
                            _changeOrder();
                          }}
                        >
                          Save Change Order
                        </button>
                      )}

                      <button
                        className="btn btn-light"
                        onClick={() => {
                          history.push({
                            pathname: "/addProperty",
                          });
                        }}
                      >
                        Add Property
                      </button>
                    </div>
                  </div>
                </div>

                {loader ? (
                  <MyLoader />
                ) : (
                  <div className="card-body table-responsive p-0">
                    <DragDropContext onDragEnd={handleDrop}>
                      <table className="table table-hover text-nowrap">
                        <thead>
                          <tr>
                            <td>Ref</td>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Address</th>
                            {/* <th>Tenure</th> */}
                            <th>Postcode</th>
                            <th>Price</th>
                            <th>Game Price</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody
                              ref={provider.innerRef}
                              {...provider.droppableProps}
                            >
                              {property.map((m, index) => (
                                <Draggable
                                  key={m._id}
                                  draggableId={m._id}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                    >
                                      <td {...provider.dragHandleProps}>
                                        {m?.referenceNumber}
                                      </td>
                                      <td {...provider.dragHandleProps}>
                                        {m?.propertyType}
                                      </td>
                                      <td {...provider.dragHandleProps}>
                                        {moment(m?.createdAt).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </td>

                                      <td {...provider.dragHandleProps}>
                                        {m?.address?.road + " "}
                                        {m?.address?.city}
                                      </td>
                                      <td {...provider.dragHandleProps}>
                                        {m?.address?.postCode}
                                      </td>
                                      <td {...provider.dragHandleProps}>
                                        {m?.price}
                                      </td>
                                      <td {...provider.dragHandleProps}>
                                        {m?.gamePrice}
                                      </td>
                                      {/* <td>{m?.referenceNumber}</td> */}

                                      <td {...provider.dragHandleProps}>
                                        {m?.isBlocked ? (
                                          <span className="badge bg-danger">
                                            Blocked
                                          </span>
                                        ) : (
                                          <span className="badge bg-success">
                                            Active
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <i
                                          className="nav-icon fas"
                                          type="button"
                                          title="view"
                                          size="2.5em"
                                          onClick={(e) => {
                                            history.push({
                                              pathname: "/singleProperty",
                                              state: m._id,
                                            });
                                          }}
                                        >
                                          <AiOutlineEye />
                                        </i>
                                        &nbsp;&nbsp;
                                        <i
                                          className="nav-icon fas"
                                          type="button"
                                          title="Edit"
                                          size="2.5em"
                                          onClick={() => {
                                            history.push({
                                              pathname: "/editProperty",
                                              state: m._id,
                                            });
                                          }}
                                        >
                                          <BiEditAlt />
                                        </i>
                                        &nbsp;&nbsp;
                                        <i
                                          className="nav-icon fas "
                                          type="button"
                                          title={
                                            m?.isBlocked ? "Unblock" : "Block"
                                          }
                                          onClick={() => {
                                            _blockProperty(m?._id);
                                          }}
                                        >
                                          <BiBlock />
                                        </i>
                                        &nbsp; &nbsp;
                                        <i
                                          className="nav-icon fas "
                                          type="button"
                                          title="Delete"
                                          // onClick={() => {
                                          //     _deleteAgent(m?._id);
                                          // }}
                                          onClick={() => {
                                            setModal(true);
                                            setId(m?._id);
                                          }}
                                        >
                                          <AiOutlineDelete />
                                        </i>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  </div>
                )}

                {property?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5">
                    No Data
                  </div>
                ) : (
                  ""
                )}

                {/* <div
                  className="card-footer"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      //display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <label>Data Per page</label>
                    <select
                      value={userPerPage}
                      className="custom-select "
                      onChange={(event) => {
                        setpageNumber(0);
                        setUserPerPage(event.target.value);
                      }}
                    >
                      <option value={25}>{25}</option>
                      <option value={50}>{50}</option>
                      <option value={100}>{100}</option>
                    </select>
                  </div>
                  <div className=" ">
                    <ReactPaginate
                      previousLabel={<span aria-hidden="true">&laquo;</span>}
                      nextLabel={<span aria-hidden="true">&raquo;</span>}
                      breakLabel={"....."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      onPageChange={(selected) => {
                        setpageNumber(selected.selected);
                        setPageChange(!pageChange);
                      }}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"page-item active"}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <h2>Delete Property</h2>
        <p>Are you sure want to delete Property.</p>
        <div className="col d-flex justify-content-between ">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setModal(false);
            }}
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              _deleteProperty(id);
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
}
