import qs from "qs";

import { makePostRequest, makeGetRequest, makePutRequest } from "./axiosBase";

export const login = async (email, password) => {
  try {
    const data = {
      email: email,
      password: password,
    };
    const config = { skipAuth: true };
    let res = await makePostRequest(
      "/admin/auth/login",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const data = {
      oldPassword,
      newPassword,
    };
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/admin/auth/changePassword",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};


export const logout = async () => {
  try {
    const data = {};
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/auth/logout",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );
   
    return res.data;
  }
  catch (error) {
    throw error;
  }
};

export const getDashBoardData = async () => {
  try {
    let res = await makeGetRequest("/admin/auth/dashboard");
    return res.data;
  } catch (error) {
    throw error;
  }
};
