import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  editProperty,
  getNextProperty,
  singleProperty,
} from "../../networkRequests/property";
import { toastError, toastSuccess, loadings } from "../../utlis/common";
import { agentList } from "../../networkRequests/agents";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { AiOutlineDelete } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { useUploadForm } from "./../../utlis/s3Upload";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { awsBaseUrl } from "../../utlis/appConstants";
import Geocode from "react-geocode";
import { useLoading } from "../../context/loadingContext";

export default function SingleProperty(props) {
  const { loadingState, setLoadingState } = useLoading();

  Geocode.setApiKey("AIzaSyCtlCG1f9GSrRnPTcDQRMdioABijsEoZwo");
  const history = useHistory();
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [sqft, setSqft] = useState("");
  const [price, setPrice] = useState(0);
  const [beds, setBeds] = useState(0);
  const [bath, setBath] = useState(0);
  const [tenure, setTenure] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const [address, setAddress] = useState("");
  const [agentId, setAgentId] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [agents, setAgents] = useState([]);
  const { isSuccess, uploadFile, progress } = useUploadForm();
  const [image, setImage] = useState([]);
  const [imageName, setImageName] = useState([]);
  const [postCode, setPostCode] = useState("");
  const [arrayofFiles, setArrayofFiles] = useState([]);
  const [thumbnailName, setThumbnailName] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState("");
  const [currency, setCurrency] = useState("");
  const [heading, setHeading] = useState("");
  const [gamePrice, setGamePrice] = useState();
  const [propertyId, setPropertyId] = useState();
  const [subHeading, setSubHeading] = useState("");

  console.log(agentId);
  const _singleProperty = async () => {
    try {
      setLoader(true);
      const res = await singleProperty(props.location.state);
      console.log(res);
      setPropertyId(res.data._id);
      setPrice(res?.data?.price);
      setSqft(res?.data?.sqft);
      setBeds(res?.data?.beds);
      setBath(res?.data?.bath);
      setTenure(res?.data?.tenure);
      setVideoUrl(res?.data?.videoUrl);
      setAgentId(res?.data?.agentId?._id);
      setDescription(res?.data?.description);
      setImageName(res?.data?.image);
      setImage(res?.data?.image);
      setCoordinates({
        lat: res?.data?.address?.loc.coordinates[1],
        lng: res?.data?.address?.loc.coordinates[0],
      });
      setCity(res?.data?.address?.city);
      setStreet(res?.data?.address?.road);
      setPostCode(res?.data?.address?.postCode);
      setAddress(res?.data?.address?.address);
      setPropertyType(res?.data?.propertyType);
      setReferenceNumber(res?.data?.referenceNumber);
      setThumbnailName(res?.data?.thumbnailName);
      setCurrency(res?.data?.currency);
      setHeading(res?.data?.heading);
      setGamePrice(res?.data?.gamePrice);
      setSubHeading(res?.data?.subHeading);
      setLoader(false);
    } catch (error) {
      console.log("output", error);
      history.push({
        pathname: "/propertyListing",
      });
      setLoader(false);
    }
  };

  const chnageThumbnailImage = (e) => {
    let file = e.target.files[0];
    const fileName = (
      file.name.split(".")[0] +
      "_" +
      uuidv4() +
      "." +
      file.name.split(".").pop()
    ).replace(/\s/g, "");
    const thumbnailNametoBeSave = uuidv4() + ".jpeg";
    const contentType = file.type;

    setThumbnailName(thumbnailNametoBeSave);
    setThumbnailFile(URL.createObjectURL(file));

    //const thumbnail = await genrateThumbnail(URL.createObjectURL(file));
    uploadFile(file, thumbnailNametoBeSave);
  };

  const _editProperty = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);

      console.log(imageName);

      const res = await editProperty(
        propertyId,
        // country,
        postCode,
        street,
        city,
        // state,
        sqft,
        price,
        beds,
        bath,
        tenure,
        videoUrl,
        description,
        coordinates.lat,
        coordinates.lng,
        agentId,
        imageName,
        thumbnailName,
        propertyType,
        referenceNumber,
        address,
        currency,
        heading,
        gamePrice,
        subHeading
      );

      toastSuccess(res.message);
      document.getElementById("submit").reset();
      // history.push({
      //   pathname: "/propertyListing",
      // });
      setLoader(false);
      // setVideoUrl("");
    } catch (error) {
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const _agentList = async () => {
    try {
      const res = await agentList(1000000, 0);
      setAgents(res.data.agents);
    } catch (error) {
      setLoader(false);
      history.push({
        pathname: "/propertyListing",
      });
      console.log(error.message);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    const fileName = (
      file.name.split(".")[0] +
      "_" +
      uuidv4() +
      "." +
      file.name.split(".").pop()
    ).replace(/\s/g, "");
    // const thumbnailNametoBeSave = uuidv4() + ".jpeg";
    const contentType = file.type;
    if (!loadingState.includes(referenceNumber)) {
      setLoadingState([...loadingState, referenceNumber]);
    }
    await uploadFile(file, fileName, contentType, referenceNumber);
    setVideoFile(URL.createObjectURL(file));
    setVideoUrl(fileName);
    //setThumbnailName(thumbnailNametoBeSave);
    //const thumbnail = await genrateThumbnail(URL.createObjectURL(file));
    //await uploadFile(thumbnail, thumbnailNametoBeSave);
  };

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const ll = await getLatLng(results[0]);
      setAddress(value);
      setCoordinates(ll);
    } catch {}
  };

  const changeAddress = async (e) => {
    // e.preventDefault();

    setAddress(e);
  };

  const _getNextProperty = async (type) => {
    try {
      setLoader(true);
      const res = await getNextProperty(propertyId, type);
      console.log(res, "kkkkkkkkkkkkk");
      setPropertyId(res.data._id);
      setPrice(res?.data?.price);
      setSqft(res?.data?.sqft);
      setBeds(res?.data?.beds);
      setBath(res?.data?.bath);
      setTenure(res?.data?.tenure);
      setVideoUrl(res?.data?.videoUrl);
      setAgentId(res?.data?.agentId?._id ?? "");
      setDescription(res?.data?.description);
      setImageName(res?.data?.image);
      setImage(res?.data?.image);
      setCoordinates({
        lat: res?.data?.address?.loc.coordinates[1],
        lng: res?.data?.address?.loc.coordinates[0],
      });
      setCity(res?.data?.address?.city);
      setStreet(res?.data?.address?.road);
      setPostCode(res?.data?.address?.postCode);
      setAddress(res?.data?.address.address);
      setPropertyType(res?.data?.propertyType);
      setReferenceNumber(res?.data?.referenceNumber);
      setThumbnailName(res?.data?.thumbnailName);
      setCurrency(res?.data?.currency);
      setHeading(res?.data?.heading);
      setGamePrice(res?.data.gamePrice);
      setSubHeading(res?.data?.subHeading);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const onSelectFile = async (e) => {
    let arrayImages = [];
    let arrayofFiles = [];

    for (var i = 0; i < e.target.files.length; i++) {
      arrayofFiles.push(e.target.files[i]);
    }

    for (var i = 0; i < arrayofFiles.length; i++) {
      // setLoader(true);
      let file = arrayofFiles[i];
      console.log(arrayofFiles, arrayofFiles[i]);
      const fileName = (
        file.name.split(".")[i] +
        "_" +
        uuidv4() +
        "." +
        file.name.split(".").pop()
      ).replace(/\s/g, "");

      arrayImages.push(fileName);
      const contentType = file.type;
      console.log(file, fileName, contentType);
      await uploadFile(file, fileName, contentType)
        .then((data) => {})
        .catch((err) => {
          return toastError(
            "There is error in uploading an image please try after some time"
          );
        });
    }
    // setImageName(imageName);

    let arrayConcat = [...imageName, ...arrayImages];

    setArrayofFiles(arrayConcat);
  };

  useEffect(() => {
    _agentList();
    _singleProperty();
  }, []);

  useEffect(() => {
    if (setCoordinates.lat) handleSelect(setCoordinates);
  }, [coordinates]);

  useEffect(() => {
    if (progress == 100 && arrayofFiles.length) {
      setImageName(arrayofFiles);
      //setVideoFile(videoUrl);
      setLoader(false);
    }
  }, [progress]);

  const removeImageFromArray = (e, index) => {
    // const index = e.target.id;

    let newPreview = [...imageName];

    // let newArrofFiles = [...arrayofFiles];
    newPreview.splice(index, 1);

    // newArrofFiles.splice(index, 1);
    setImageName(newPreview);
    // setPreview(newPreview);
    // setArrayofFiles(newArrofFiles);
  };

  return (
    <div>
      {loader ? loadings : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Property</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/driver">Property</NavLink>
                </li>
                <li className="breadcrumb-item active">Edit</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className=" row mx-3 px-1">
        <div className="card card-info shadow-0 col-sm-12 px-0">
          <div
            className="card-header"
            style={{
              background: "#tranparent",
              flexDirection: "row",
              alignItems: "Center",
            }}
          >
            <h3 className="card-title">Edit Property</h3>
            <div className="card-tools ml-4">
              <div>
                <button
                  className="btn btn-light"
                  onClick={() => {
                    _getNextProperty("next");
                  }}
                >
                  Next{">>"}
                </button>
              </div>
            </div>
            <div className="card-tools">
              <div>
                <button
                  className="btn btn-light"
                  onClick={() => {
                    _getNextProperty("pre");
                  }}
                >
                  {"<<"} Previous
                </button>
              </div>
            </div>
          </div>

          <form id="submit">
            <div className="card-body">
              <div className="form-group">
                <label>Agents</label>
                <select
                  defaultValue={agentId}
                  value={agentId}
                  className="custom-select"
                  scrollable="true"
                  onChange={(event) => setAgentId(event.target.value)}
                >
                  <option value={""}>select agent</option>
                  {agents?.map((item, idx) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>type</label>
                <select
                  value={propertyType}
                  className="custom-select"
                  scrollable="true"
                  onChange={(event) => setPropertyType(event.target.value)}
                >
                  <option value="" disabled selected hidden>
                    select property type
                  </option>

                  <option value={"FOR SALE"}>FOR SALE</option>
                  <option value={"SOLD"}>SOLD</option>
                  <option value={"INFORMATION"}>INFORMATION</option>
                  <option value={"INSPIRATION"}>INSPIRATION</option>
                  <option value={"ADVERTISMENT"}>ADVERTISMENT</option>
                  <option value={"FOR RENT"}>FOR RENT</option>
                </select>
              </div>
              <div className="form-group">
                <label>Reference Number</label>
                <input
                  //type="password"
                  // required
                  value={referenceNumber}
                  autofocus
                  className="form-control"
                  placeholder="Reference Number"
                  onChange={(e) => {
                    setReferenceNumber(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Price</label>
                <input
                  required
                  autofocus
                  value={price ? price : ""}
                  className="form-control"
                  placeholder="Price"
                  onChange={(e) => {
                    setPrice(e.target.value.replace(/\D/g, ""));
                  }}
                />
              </div>
              <div className="form-group">
                <label>Game Price</label>
                <input
                  required
                  autofocus
                  value={gamePrice ? gamePrice : ""}
                  className="form-control"
                  placeholder="Price"
                  onChange={(e) => {
                    setGamePrice(e.target.value.replace(/\D/g, ""));
                  }}
                />
              </div>
              <div className="form-group">
                <label>Currency</label>
                <input
                  //type="password"
                  // required
                  autofocus
                  value={currency}
                  className="form-control"
                  placeholder="currency"
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Heading</label>
                <input
                  //type="password"
                  // required
                  value={heading}
                  autofocus
                  className="form-control"
                  placeholder="heading"
                  onChange={(e) => {
                    setHeading(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Sub Heading</label>
                <input
                  //type="password"
                  // required
                  value={subHeading}
                  autofocus
                  className="form-control"
                  placeholder="sub heading"
                  onChange={(e) => {
                    setSubHeading(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Beds</label>
                <input
                  // type="number"
                  required
                  autofocus
                  value={beds ? beds : ""}
                  className="form-control"
                  placeholder="Beds"
                  onChange={(e) => {
                    setBeds(e.target.value.replace(/\D/g, ""));
                  }}
                />
              </div>
              <div className="form-group">
                <label>Bath</label>
                <input
                  //type="password"
                  required
                  autofocus
                  value={bath ? bath : ""}
                  className="form-control"
                  placeholder="Bath"
                  onChange={(e) => {
                    setBath(e.target.value.replace(/\D/g, ""));
                  }}
                />
              </div>
              <div className="form-group">
                <label>Area(sqft)</label>
                <input
                  // type="number"
                  required
                  autofocus
                  value={sqft || ""}
                  className="form-control"
                  placeholder="City"
                  onChange={(e) => {
                    setSqft(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Road</label>
                <input
                  // type="number"
                  required
                  autofocus
                  value={street ? street : ""}
                  className="form-control"
                  placeholder="Street"
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>PostCode</label>
                <input
                  // type="number"
                  required
                  autofocus
                  value={postCode ? postCode : ""}
                  className="form-control"
                  placeholder="postCode"
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>City</label>
                <input
                  // type="number"
                  required
                  autofocus
                  value={city ? city : ""}
                  className="form-control"
                  placeholder="City"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Tenure</label>
                <input
                  // type="number"
                  required
                  autofocus
                  value={tenure ? tenure : ""}
                  className="form-control"
                  placeholder="Leasehold"
                  onChange={(e) => {
                    setTenure(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  //type="password"
                  value={description ? description : ""}
                  required
                  autofocus
                  className="form-control"
                  placeholder="Description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              {/* <div className="form-group">
                                <label>Country</label>
                                <input
                                    // type="number"
                                    required
                                    autofocus
                                    value={country ? country : ""}
                                    className="form-control"
                                    placeholder="Country"
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>State</label>
                                <input
                                    // type="number"
                                    required
                                    autofocus
                                    value={state ? state : ""}
                                    className="form-control"
                                    placeholder="State"
                                    onChange={(e) => {
                                        setState(e.target.value);
                                    }}
                                />
                            </div> */}
              <div className="form-group">
                <label>Address</label>
                <div>
                  <PlacesAutocomplete
                    value={address}
                    onChange={(e) => {
                      changeAddress(e);
                    }}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Location on map",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions?.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={suggestion?.placeId}
                              >
                                <span>{suggestion?.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="form-group">
                <label for="inputProjectLeader">Images Preview</label>
                {imageName.map((img, index) => (
                  <div key={index}>
                    <img
                      className="profile-user-img img-fluid"
                      src={awsBaseUrl + img}
                      id={index}
                      alt="loading..."
                    />
                    <i
                      id={index}
                      key={index}
                      title="Delete"
                      onClick={(e) => {
                        removeImageFromArray(e, index);
                      }}
                    >
                      <AiOutlineDelete />
                    </i>
                    {/* <button
                      id={index}
                      key={index}
                      onClick={(e) => {
                        removeImageFromArray(e);
                      }}
                    /> */}
                  </div>
                ))}
              </div>
              <div className="form-group">
                <label for="exampleInputFile">Change Images</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      name="file"
                      multiple
                      onChange={onSelectFile}
                      accept="image/*"
                      // type="file"
                      // singleFile={true}
                      className="form-control"
                      // onChange={handleImageChange}
                    />
                    {loader ? <CircularProgress value={progress} /> : ""}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label for="inputProjectLeader">Preview</label>
                {videoUrl === "" ? (
                  "  No video preview available"
                ) : (
                  <>
                    {/* <div className="embed-responsive embed-responsive-16by9 w-50"> */}
                    <iframe
                      className="embed-responsive-item"
                      src={videoFile ? videoFile : awsBaseUrl + videoUrl}
                      allowfullscreen
                    />

                    {/* </div> */}
                    <i
                      style={{ cursor: "pointer" }}
                      title="Delete video"
                      onClick={() => {
                        setVideoFile("");
                        setVideoUrl("");
                        //removeImageFromArray(e, index);
                      }}
                    >
                      <AiOutlineDelete />
                    </i>
                  </>
                )}
              </div>
              <div className="form-group">
                <label for="exampleInputFile">Change Property Video</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      singleFile={true}
                      className="form-control"
                      accept="video/*"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                    {loader ? <CircularProgress value={progress} /> : ""}
                  </div>
                </div>
              </div>
              {/* ////////////////////////////////////////// */}

              <div className="form-group">
                <label for="inputProjectLeader">Video Thumbnail Preview</label>
                {thumbnailName && (
                  <div>
                    <img
                      className="profile-user-img img-fluid"
                      src={
                        thumbnailFile
                          ? thumbnailFile
                          : awsBaseUrl + thumbnailName
                      }
                      //id={index}
                      //alt="loading..."
                    />
                  </div>
                )}
              </div>

              <div className="form-group">
                <label for="exampleInputFile">Change Video Thumbnail</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      singleFile={true}
                      className="form-control"
                      accept="image/*"
                      onChange={chnageThumbnailImage}
                    />
                    {loader ? <CircularProgress value={progress} /> : ""}
                  </div>
                </div>
              </div>

              <div>
                <button
                  className="btn  mr-3"
                  onClick={_editProperty}
                  style={{ background: "#17a2b8", color: "white" }}
                >
                  Edit
                </button>
                {/* <button type="reset" className="btn btn-secondary">
                                    Reset
                                </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
