import React, { useState, useMemo, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { addAgent } from "../../networkRequests/agents";
import { toastError, toastSuccess, loadings } from "../../utlis/common";
import { CircularProgress } from "@mui/material";
import { useUploadForm } from "./../../utlis/s3Upload";

export default function AddAgent() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");

  const { isSuccess, uploadFile, progress } = useUploadForm();

  const handleImageChange = async (e) => {
    e.preventDefault();
    setLoader(true);
    console.log("hiiiii", e.target.files[0]);
    let file = e.target.files[0];
    const fileName =
      file.name.split(".")[0] +
      "_" +
      uuidv4() +
      "." +
      file.name.split(".").pop();
    setImageName(fileName);
    setImage(URL.createObjectURL(file));
    const contentType = file.type;
    uploadFile(file, fileName, contentType)
      .then((data) => {
        if (progress == 100) {
          setLoader(false);
        }
      })
      .catch((err) => {
        return toastError(
          "There is error in uploading an image please try after some time"
        );
      });
  };

  useEffect(() => {
    if (progress === 100) {
      setLoader(false);
    }
  }, [progress]);

  const _addAgent = async (e) => {
    e.preventDefault();
    if (loader) {
      return toastError("File is uploading");
    }
    if (!name) {
      return toastError("Please enter a name");
    }
    // if (!countryCode) {
    //   return toastError("Please enter country Code");
    // }
    // if (!description) {
    //   return toastError("Please enter description");
    // }
    // if (!imageName) {
    //   return toastError("Please add an image");
    // }

    // if (!name || !phone || !countryCode || !description || !imageName) {
    //   return toastError("Please fill all fields");
    // }

    try {
      setLoader(true);
      const res = await addAgent(
        name,
        description,
        email,
       
        imageName
      );
      toastSuccess(res.message);
      document.getElementById("submit").reset();
      setImage("");
      setLoader(false);
    } catch (error) {
      toastError(error.data.message);
      setLoader(false);
    }
  };

  return (
    <div>
      {loader ? loadings : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Agent</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/addAgent">Agent</NavLink>
                </li>
                <li className="breadcrumb-item active">Add</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className=" row mx-3 px-1">
        <div className="card card-info shadow-0 col-sm-12 px-0">
          <div
            className="card-header "
            // style={{ backgroundColor: "#5CC0B9" }}
          >
            <h6 className=" my-2">Add Agent</h6>
          </div>

          <form id="submit">
            <div className="card-body">
              <div className="form-group">
                <label>Name</label>
                <input
                  // required
                  autofocus
                  className="form-control"
                  placeholder="Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  rows={10}
                  type="text"
                  // required
                  autofocus
                  className="form-control"
                  placeholder="Description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    // required
                    autofocus
                    className="form-control"
                    placeholder="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label for="inputProjectLeader">Preview</label>
                {image === "" ? (
                  ""
                ) : (
                  <div>
                    <img
                      class="profile-user-img img-fluid "
                      src={image}
                      alt="loading....."
                    />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label for="exampleInputFile">Profile Image</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      singleFile={true}
                      className="form-control"
                      onChange={handleImageChange}
                    />
                    {loader ? <CircularProgress value={progress} /> : ""}
                  </div>
                </div>
              </div>

              <div>
                <button type="reset" className="btn btn-secondary">
                  Reset
                </button>
                <button
                  // type="submit"
                  className="btn  mr-3"
                  onClick={_addAgent}
                  style={{ background: "#17a2b8", color: "white" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
